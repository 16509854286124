import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import BackgroundImage from 'gatsby-background-image';

/**
 * In this functional component a <BackgroundImage />  is compared to an <Img />.
 * @param className   string    className(s) from styled-components.
 * @param children    nodes     Child-components from the including page
 * @parm image        path      Image path resolved by GraphQL, and further transformed by plugins
 * @return {*}
 * @constructor
 */
const BackgroundSection = ({ className, children, image }) => {
  const imageData = image ? image.childImageSharp.fluid : {};

  return (
    <BackgroundImage
      Tag="section"
      className={className}
      // To style via external CSS see layout.css last examples:
      // className="test"
      fluid={imageData}
      backgroundColor={'#040e18'}
      // Title get's passed to both container and noscriptImg.
      title="specializzazioni-intro"
      // style={{
      //   // Defaults are overwrite-able by setting one of the following:
      //   // backgroundSize: '',
      //   // backgroundPosition: '',
      //   // backgroundRepeat: '',
      // }}
      // To "force" the classic fading in of every image (especially on
      // imageData change for fluid / fixed) by setting `soft` on `fadeIn`:
      // fadeIn={`soft`}
      // To be able to use stacking context changing elements yourself,
      // set this to true to disable the "opacity hack":
      // preserveStackingContext={true}
      // You can "safely" (look them up beforehand ; ) add other props:
      id="specializzazioni-intro"
      role="img"
      aria-label="specializzazioni-intro"
    >
      {children}
    </BackgroundImage>
  );
};

BackgroundSection.propTypes = {
  className: PropTypes.string,
  children: PropTypes.nodes,
  image: PropTypes.object,
};

const StyledSpecializzazioniIntro = styled(BackgroundSection)`
  width: 100vw;
  height: 15vw;
  // These three crucial styles (if existing) are directly parsed and added to
  // the pseudo-elements without further ado (except when overwritten).
  //background-repeat: repeat-y;
  //background-position: left center;
  //background-size: cover;
  margin-bottom: 15vh;
`;

export default StyledSpecializzazioniIntro;
