import React from 'react';
import PropTypes from 'prop-types';
import Layout from 'components/layout';
import PageTitle from 'components/page-title';
import StyledSpecializzazioniIntro from 'components/chi-sono/introbar';
import {
  StyledContainer,
  StyledFigure,
  StyledImageContainer,
  StyledSideImageContainer,
  StyledImage,
  StyledSideImage,
  StyledCaption,
  BiographyContainer,
  StyledListContainer,
  StyledListTitle,
  StyledListEnder,
  TextContainer,
  SubTitle,
  Title,
  Copy,
  IconsContainer,
  IconContainer,
  StyledIconImg,
  StyledIconLink,
} from 'components/chi-sono/chi-sono.css';

import { graphql } from 'gatsby';
import styled from 'styled-components';

const ChiSono = ({ data }) => (
  <Layout>
    <StyledSpecializzazioniIntro image={data.chiSonoJson.introImage} />
    <PageTitle>{data.chiSonoJson.pageTitle}</PageTitle>
    <StyledContainer>
      <StyledImageContainer>
        <figure style={{width: '100%'}}>
          <StyledImage
            fluid={
              data.chiSonoJson.sideImage
                ? data.chiSonoJson.sideImage.childImageSharp.fluid
                : {}
            }
            alt="Specializzazioni"
          />
          <StyledCaption>
            <Title>{data.chiSonoJson.photoTitle}</Title>
            <Copy>{data.chiSonoJson.subTitle}</Copy>
          </StyledCaption>
        </figure>
      </StyledImageContainer>
      <TextContainer>
        <BiographyContainer
            dangerouslySetInnerHTML={{"__html":"<p>Sono Lara Bartali, fiorentina di nascita vivo a Pistoia da qualche anno e qui lavoro come fisioterapista.\
              Nel mio percorso tra Prato e Pistoia ho vissuto realtà riabilitative diverse: dall’infortunio muscolo-scheletrico dello sportivo,\
              alle problematiche posturali infantili e adolescenziali, dalla riabilitazione di gruppo\
              o individuale nel settore neurologico, a quella pre- e post operatoria nel campo ortopedico.<br/><br/>\
              Rivolgo particolare attenzione all’interazione con i più piccoli dove \
              la riabilitazione diventa gioco e la prevenzione di disordini strutturali \
              passa in primo piano.<br/><br/>\
              Ho imparato a non sottovalutare mai in ambito neurologico la propriocezione\
              e quella la sensibilità tattile che troppo spesso viene sottovalutata\
              e se affrontata correttamente porta ad un recupero migliore.<br/><br/>\
              Anche i massaggi fanno parte della fisioterapia e non sono di poca\
              importanza, anzi, sono fondamentali perché influiscono sulla tempistica\
              della guarigione, che si tratti di semplici contratture ad esempio o di edemi da drenare.</p>"}}
        />
        <SubTitle>Come puoi contattarmi?</SubTitle>
        <IconsContainer>
          {data.footerJson.icons.map((item, i) => (
            <IconContainer key={"icon-container-"+i.toString()}>
              <StyledIconImg
                fluid={item.image ? item.image.childImageSharp.fluid : {}}
                key={"icon-"+i.toString()} />
              <StyledIconLink key={"link-"+i.toString()} href={item.link}>
                {item.title}
              </StyledIconLink>
            </IconContainer>
          ))}
        </IconsContainer>
      </TextContainer>
      <StyledSideImageContainer>
        <figure style={{width: '100%'}}>
          <StyledSideImage
            fluid={
              data.chiSonoJson.otherImage
                ? data.chiSonoJson.otherImage.childImageSharp.fluid
                : {}
            }
            alt="Specializzazioni"
          />
        </figure>
      </StyledSideImageContainer>
    </StyledContainer>
  </Layout>
);

ChiSono.propTypes = {
  data: PropTypes.object.isRequired,
};

export default ChiSono;

export const query = graphql`
  query CurriculumQuery {
    chiSonoJson {
      pageTitle
      photoTitle
      subTitle
      sideImage {
        childImageSharp {
          fluid(maxWidth: 1000, quality: 90) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      introImage {
        childImageSharp {
          fluid(maxWidth: 1000, quality: 90) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      otherImage {
        childImageSharp {
          fluid(maxWidth: 1000, quality: 90) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
    homeJson {
      landing {
        logo {
          childImageSharp {
            fluid(maxWidth: 1000, quality: 90) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    }
    footerJson {
      icons {
        title
        link
        image {
          childImageSharp {
            fluid(maxWidth: 1000, quality: 90) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    }
  }
`;
