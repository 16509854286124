import styled from 'styled-components';
import MEDIA from 'helpers/mediaTemplates';
import Img from 'gatsby-image';

export const Title = styled.span`
  display: block;
  font-size: 2rem;
  font-weight: 500;
  margin: 2rem 2rem 1rem;
  color: #1a5d8f;
  text-align: center;
`;

export const Copy = styled.p`
  color: #757575;
  margin: 0 2rem 2rem;
  color: #1a5d8f;
  text-align: center;
`;

export const StyledFigure = styled.figure`
  width: 100%;
`;

export const StyledCaption = styled.figcaption`
  width: 100%;
`;

export const StyledContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 80vw;
  padding-bottom: 5rem;
  margin: auto;
`;

export const BiographyContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0 2rem;

  margin-bottom: 15vh;

  ${MEDIA.TABLET`

  `}
`;

export const StyledListContainer = styled.div`
  padding-bottom: 1rem;
`;

export const StyledListEnder = styled.div`
  margin-top: 1rem;
  border-bottom: 2px solid #1a5d8f;
  width: 15%;
`;

export const StyledListTitle = styled.h2`
  font-size: 3rem;
  color: #1a5d8f;
  margin-bottom: 3rem;
`;

export const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const SubTitle = styled.h2`
  color: #1a5d8f;
  font-size: 4rem;
  margin: 1rem 0;
`;

export const StyledImageContainer = styled.div`
  display: flex;
  width: 50%;
  min-width: 25vw;
  ${MEDIA.TABLET`
    width: 100%;
  `}
`;

export const StyledSideImageContainer = styled.div`
  display: flex;
  width: 33%;
  min-width: 25vw;
  ${MEDIA.TABLET`
    width: 100%;
  `}
`;

export const StyledImage = styled(Img)`
  width: 25vw;
  height: 25vw;
  border-radius: 50%;
`;

export const StyledSideImage = styled(Img)`
  width: 15vw;
  height: 15vw;
  border-radius: 50%;
`;

export const IconsContainer = styled.div`
  display: flex;
  flex-direction: column;
  ${MEDIA.TABLET`
    margin-left: 0;
    width: 100%;
  `};
`;

export const IconContainer = styled.div`
  display: flex;
  flex: 1 0 auto;
  /* justify-content: center; */
  align-items: center;
`;

export const StyledIconImg = styled(Img)`
  width: 1.5rem;
  display: inline-block;
  /* background-color: #fff; */
  padding: 1rem;
`;

export const StyledIconLink = styled.a`
  color: #1a5d8f;
  margin-left: 2rem;
`;
